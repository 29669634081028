.btn-delete-file {
    cursor: pointer;
    border-radius: 0.2rem;
    padding: 3px 12px;
    margin-bottom: 0;
    background-color: #fff;
    color: red;
    border: 1px solid red;
    font-family: sans-serif
}

    .btn-delete-file:hover {
        color: #fff;
        background-color: red;
        border-color: red;
    }

.btn-delete-file-disable {
    cursor: not-allowed;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.2rem;
    padding: 3px 12px;
    margin-bottom: 0;
    background-color: #ffffff;
    color: grey;
    font-family: sans-serif
}

.btn-delete-file-hide {
    display: none !important;
}

.btn-copy-file {
    cursor: pointer;
    border-radius: 0.2rem;
    padding: 3px 12px;
    margin-bottom: 0;
    background-color: #fff;
    color: #ecbf38;
    border: 1px solid #ecbf38;
    font-family: sans-serif
}

    .btn-copy-file:hover {
        color: #fff;
        background-color: #ecbf38;
        border-color: #ecbf38;
    }

.btn-edit-file {
    cursor: pointer;
    border-radius: 0.2rem;
    padding: 3px 12px;
    margin-bottom: 0;
    background-color: #fff;
    color: #17a2b8;
    border: 1px solid #17a2b8;
    font-family: sans-serif
}

    .btn-edit-file:hover {
        color: #fff;
        background-color: #17a2b8;
        border-color: #17a2b8;
    }

.btn-edit-file-disable {
    cursor: not-allowed;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.2rem;
    padding: 3px 12px;
    margin-bottom: 0;
    background-color: #ffffff;
    color: grey;
    font-family: sans-serif
}

.btn-edit-file-hide {
    display: none !important;
}

.btn-add {
    cursor: pointer;
    border-radius: 0.2rem;
    padding: 3px 12px;
    margin-bottom: 0;
    background-color: #fff;
    color: #77B817;
    border: 1px solid #77B817;
}

    .btn-add:hover {
        color: #fff;
        background-color: #77B817;
        border-color: #77B817;
    }

.btn-add-disable {
    cursor: not-allowed;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.2rem;
    padding: 3px 12px;
    margin-bottom: 0;
    background-color: #ffffff;
    color: grey;
}

.btn-add-hide {
    display: none !important;
}

.employee-font {
    font-family: sans-serif
}
