/* Provide sufficient contrast against white background */
@import url('https://fonts.googleapis.com/css?family=Kanit');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    zoom: 90%;
}

@media (min-width: 768px) {
    .container {
        max-width: 100%;
    }

    .custom-datepicker {
        width: 150px
    }
}

@media (min-width: 576px) {
    .container {
        max-width: 100%;
    }

    .custom-datepicker {
        width: 100px
    }

    .custom-modal {
        max-width: 80%;
    }
}

@media (max-width: 1500px) {
    .custom-datepicker {
        width: 180px
    }
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

/*.Loader {
    height: 100vh;
}

.Loader__background {
    display: none !important;
    z-index: 2000 !important;
    position: fixed !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

    .Loader__background.hide {
        display: none !important;
    }

    .Loader__background.show {
        display: block !important;
    }*/


.trapezoid {
    border-bottom: 32px solid #3397c3;
    border-right: 25px solid transparent;
    height: 0;
    width: 200px;
    color: white;
    margin-top: 8px;
}

.trapezoid.active {
    height: 30px;
    box-shadow: 0 5px 5px -5px #333;
}

.trapezoid-purple {
    border-bottom: 32px solid #a665af;
}

.trapezoid-blue {
    border-bottom: 32px solid #3d5a80;
}

.trapezoid>span {
    display: inline-block;
    color: white;
    font-size: 14px;
    /* font-weight: bold; */
    padding: 8px 0px 8px 8px;
}

.trapezoid-head {
    border-bottom: 32px solid #3397c3;
    border-right: 25px solid transparent;
    height: 0;
    width: 200px;
    color: white;
    margin-top: 8px;
    text-align: center;
}

.trapezoid-head>span {
    display: inline-block;
    color: white;
    font-size: 14px;
    padding: 8px 8px 8px 8px;
}

.mat-box {
    border-radius: 0px 5px 5px 5px;
    border: 1px solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    border-color: #e5e6e9 #dfe0e4 #d0d1d5;
    background: #fff;
    padding: 15px;
    margin-bottom: 10px;
}

.master-data-header {
    background: #fff;
    height: 57px;
    overflow: hidden;
    padding: 7px 20px;
    border-bottom: 1px solid #ddd;
    position: relative;
}

.master-data-content {
    /*padding: 15px 20px;
    background: #f7f7f7;*/
}

.nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #a665af;
    border-color: #dee2e6 #dee2e6 #fff;
}

.pointer {
    cursor: pointer;
}

.horizon-tab {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
}

/*.scrollbar {
    margin-left: 30px;
    float: left;
    height: 300px;
    width: 65px;
    background: #F5F5F5;
    overflow-y: scroll;
    margin-bottom: 25px;
}
*/
.custom-scrollbar::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #F5F5F5;
}

.custom-scrollbar::-webkit-scrollbar {
    height: 8px;
    width: 1px;
    background-color: #F5F5F5;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #adb5bd;
}

.task-success {
    background-color: #a1ff0a
}

.task-error {
    background-color: #ff0000
}

.task-warning {
    background-color: #e8aa14
}

.task-running {
    background-color: #4cc9f0
}

.require-field {
    background: #fcffde;
    border-left: 3px solid #dea80d;
    background-color: white !important;
}

.blue-require-style {
    border-left: 3px solid #9FA8DA;
    background-color: white !important;
}

.green-require-style {
    border-left: 3px solid #90be6d;
    background-color: white !important;
}

.my-modal-custom {
    width: 90vw;
    max-width: 90vw;
}

.modal-backdrop {
    min-width: 100%;
    min-height: 100%;
}

.Loader__background {
    z-index: 2000
}

.custom-table-list {
    border-collapse: collapse;
    width: 100%;
    border: solid 1px #c0c0c0;
    font-family: sans-serif;
    font-size: 12px;
    table-layout: fixed;
    /*font-weight: 200*/
}

.login-full-screen {
    background-image: url(./image/login-image.jpg);
    background-position: top center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.login-middle {
    position: relative;
    display: table;
    width: 100%;
    height: 100%;
    line-height: 100%;
}

dropzone {
    width: 100%;
    height: 20%;
    border: 1px solid black;
}

.custom-datepicker {
    width: 220px
}

bg-isactive {
    background-color: #ffe97f
}

.front-modal {
    z-index: 1200
}

.front-backdrop-modal {
    z-index: 1050
}

.text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tags {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    gap: 1rem;
}

.tag {
    --color: var(--light-color);
    font-family: var(--regular-font);
    font-size: 1rem;
    border-radius: 100rem;
    box-shadow: 0 0 0 2px var(--light-color);
    background-color: transparent;
    color: var(--dark-color);
    font-weight: bold;
    transition: background-color 250ms ease, box-shadow 250ms ease;
}

.tag>a {
    display: inline-block;
    position: relative;
    color: var(--dark-color);
    text-decoration: none;
    padding: .5rem 1rem .5rem 2rem;
}

.tag>a::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 1rem;
    width: .5rem;
    height: .5rem;
    border-radius: 100%;
    background-color: var(--color);
    transform: translateY(-50%);
}

.tag:hover {
    background-color: var(--light-color);
    box-shadow: 0 0 0 calc(2px + .25rem) var(--light-color);
}

.css-4ag5cd-DropDown {
    z-index: 9999
}

.border-bold-right {
    border-right: 2px solid
}


table.scroll-table tbody.scroll-table {
    display: block;
    max-height: 300px;
    overflow-y: scroll;
}


table.scroll-table thead.scroll-table,
table.scroll-table tbody.scroll-table tr.scroll-table {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.custom-modal {
    max-width: 98%;
}

.tableWrap {
    height: 100%;
    border: 2px solid black;
    overflow: auto;
}

.hasSuspend-y {
    background-color: #EEEEEE
}

.Actual-error {
    background-color: rgb(228, 177, 171)
}

.react-datepicker__input-container,
.react-datepicker-wrapper {
    width: 100%;
}

.td-legacylog::-webkit-scrollbar {
    height: 4px;
}

.td-legacylog::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.td-legacylog::-webkit-scrollbar-thumb {
    background: #888;
}

.modal-dialog {
    margin: 0.5rem auto;
}